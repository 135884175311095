<template>
  <div class="page">
    <div class="header_box">
      <div class="header">
        <div class="username">
          <div class="name">{{ username }}</div>
          <div class="layout">更换学生</div>
        </div>
        <div class="school" v-if="school != ''">学校：{{ school }}</div>
        <div class="class">班级：{{ classes }}</div>
      </div>
    </div>
    <div class="mains">
      <div class="nopay">
        <div
          class="nopayItem"
          v-for="item in wait_pay_list"
          :key="item.project_bill_id"
          @click="gopayDetails(item)"
        >
          <img
            src="../../assets/img/icon_wait.png"
            alt=""
            style="width: 50px"
          />
          <div class="payName">{{ item.project_name }}</div>
          <div class="name">姓名：{{ item.stu_name }}</div>
          <div class="school">学校：{{ item.school_name }}</div>
          <div class="amount">￥{{ item.total_money }}</div>
        </div>
      </div>
      <div class="pay">
        <div
          class="payItem"
          v-for="item in paid_list"
          :key="item.project_bill_id"
          @click="gopayDetails(item)"
        >
          <div class="icon">
            <img
              src="../../assets/img/icon_money.png"
              alt=""
              style="width: 30px"
            />
          </div>
          <div class="info">
            <div class="info_title">{{ item.project_name }}</div>
            <div class="info_money">￥{{ item.total_money }}</div>
          </div>
          <div class="status">缴费成功</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderList } from "@/api/topay.js";
import { Toast } from "vant";

export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      username: JSON.parse(localStorage.getItem("header")).stu_name,
      school: "",
      // school: JSON.parse(localStorage.getItem('header')).school_name,
      classes: JSON.parse(localStorage.getItem("header")).classes,
      wait_pay_list: [],
      paid_list: [],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getOrderList();
  },
  methods: {
    gopayDetails(item) {
      this.$router.push({
        path: "/payDetails",
        query: {
          id: item.project_bill_id,
          status: item.status,
          project_name: item.project_name,
        },
      });
    },
    getOrderList() {
      let info = {
        number: JSON.parse(localStorage.getItem("header")).stu_id_no,
        name: JSON.parse(localStorage.getItem("header")).stu_name,
        school_id: JSON.parse(localStorage.getItem("projectInfo")).school_id,
      };
      const data = {
        request: JSON.stringify(info),
      };
      orderList(data).then((res) => {
        if (res.code == 0) {
          this.paid_list = res.data.paid_list;
          this.wait_pay_list = res.data.wait_pay_list;
          if (this.wait_pay_list.length != 0) {
            this.school = this.wait_pay_list[0].school_name;
          } else if (this.paid_list.length != 0) {
            this.school = this.paid_list[0].school_name;
          } else {
            this.school = "";
          }
        } else {
          Toast(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.page {
  width: 100%;
  background-color: #f9f9f9;
  .header_box {
    background-color: #fff;
    height: 120px;
    color: #333;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 20;
    border-bottom: 1px solid #dedede;
    overflow: hidden;
    .header {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 30px;
      overflow: hidden;

      // justify-content: space-around;
      .username {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          font-size: 16px;
        }
        .layout {
          color: #2ab1f3;
        }
      }
      .school {
        margin-top: 10px;
      }
      .class {
        margin-top: 10px;
      }
    }
  }
  .mains {
    margin-top: 160px;
    padding: 0 15px;
    .nopay {
      .nopayItem {
        position: relative;
        padding: 15px;
        border: solid 1px #dedede;
        margin-bottom: 15px;
        background-color: #fefefe;
        width: 100%;
        height: 150px;
        img {
          position: absolute;
          top: 0;
          right: 0;
        }
        .payName {
          font-size: 16px;
        }
        .name {
          margin-top: 15px;
        }
        .school {
          margin-top: 10px;
          padding-bottom: 15px;
          border-bottom: 2px solid #dedede;
        }
        .amount {
          margin-top: 15px;
          color: #29b0f0;
          font-size: 18px;
        }
      }
    }
    .pay {
      .payItem {
        width: 100%;
        height: 80px;
        padding: 0 15px;
        background-color: #fefefe;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: solid 1px #dedede;
        margin-bottom: 15px;
        .info {
          width: 200px;
          .info_title {
            font-size: 16px;
            color: #999999;
          }
          .info_money {
            margin-top: 10px;
            color: #9ddfff;
            font-size: 16px;
          }
        }
        .status {
          font-size: 14px;
          color: #a4dea4;
        }
      }
    }
  }
}
</style>
