<template>
  <div v-cloak>
    <van-tabs v-model="status" sticky @change="changeTabs">
      <van-tab title="待审核" name="2">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getData"
        >
          <div
            class="outer-box"
            v-for="(item, index) in list"
            :key="item.student_id"
          >
            <div class="info-box">
              <div class="info-content">
                <div class="info-box-one">
                  <div>姓名:{{ item.name }}</div>
                  <div>{{ item.gender == 1 ? "男" : "女" }}</div>
                  <div>{{ item.minzu }}</div>
                </div>
                <div class="info-box-two">
                  <div>身高:{{ item.height }}cm</div>
                  <div>体重:{{ item.weight }}kg</div>
                  <div>成绩:{{ item.last_score }}分</div>
                </div>
                <div class="info-box-three">
                  <div>毕业学校:{{ item.finish_school }}</div>
                  <div>身份证:{{ item.id_card }}</div>
                </div>
                <div class="info-box-four">
                  <div>监护人:{{ item.fname }}</div>
                  <div>{{ item.mobile }}</div>
                </div>
              </div>
              <div class="info-btn">
                <van-button type="info" size="small" @click="showPopup(index)">
                  通过</van-button
                >
                <van-button type="info" size="small" @click="subPrice(6, index)"
                  >搁置</van-button
                >
                <van-button type="info" size="small" @click="subPrice(1, index)"
                  >拒绝</van-button
                >
              </div>
            </div>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="搁置" name="6">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getData"
        >
          <div
            class="outer-box"
            v-for="(item, index) in list"
            :key="item.student_id"
          >
            <div class="info-box">
              <div class="info-content">
                <div class="info-box-one">
                  <div>姓名:{{ item.name }}</div>
                  <div>{{ item.gender == 1 ? "男" : "女" }}</div>
                  <div>{{ item.minzu }}</div>
                </div>
                <div class="info-box-two">
                  <div>身高:{{ item.height }}cm</div>
                  <div>体重:{{ item.weight }}kg</div>
                  <div>成绩:{{ item.last_score }}分</div>
                </div>
                <div class="info-box-three">
                  <div>毕业学校:{{ item.finish_school }}</div>
                  <div>身份证:{{ item.id_card }}</div>
                </div>
                <div class="info-box-four">
                  <div>监护人:{{ item.fname }}</div>
                  <div>{{ item.mobile }}</div>
                </div>
              </div>
              <div class="info-btn">
                <van-button type="info" size="small" @click="showPopup(index)">
                  通过</van-button
                >
                <van-button type="info" size="small" @click="subPrice(1, index)"
                  >拒绝</van-button
                >
              </div>
            </div>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="待缴费" name="3">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getData"
        >
          <div class="outer-box" v-for="item in list" :key="item.student_id">
            <div class="info-box">
              <div class="info-content">
                <div class="info-box-one">
                  <div>姓名:{{ item.name }}</div>
                  <div>{{ item.gender == 1 ? "男" : "女" }}</div>
                  <div>{{ item.minzu }}</div>
                </div>
                <div class="info-box-two">
                  <div>身高:{{ item.height }}cm</div>
                  <div>体重:{{ item.weight }}kg</div>
                  <div>成绩:{{ item.last_score }}分</div>
                </div>
                <div class="info-box-three">
                  <div>毕业学校:{{ item.finish_school }}</div>
                  <div>身份证:{{ item.id_card }}</div>
                </div>
                <div class="info-box-four">
                  <div>监护人:{{ item.fname }}</div>
                  <div>{{ item.mobile }}</div>
                </div>
              </div>
            </div>
          </div>
        </van-list></van-tab
      >
      <van-tab title="已缴费" name="4"
        ><van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getData"
        >
          <div class="outer-box" v-for="item in list" :key="item.student_id">
            <div class="info-box">
              <div class="info-content">
                <div class="info-box-one">
                  <div>姓名:{{ item.name }}</div>
                  <div>{{ item.gender == 1 ? "男" : "女" }}</div>
                  <div>{{ item.minzu }}</div>
                </div>
                <div class="info-box-two">
                  <div>身高:{{ item.height }}cm</div>
                  <div>体重:{{ item.weight }}kg</div>
                  <div>成绩:{{ item.last_score }}分</div>
                </div>
                <div class="info-box-three">
                  <div>毕业学校:{{ item.finish_school }}</div>
                  <div>身份证:{{ item.id_card }}</div>
                </div>
                <div class="info-box-four">
                  <div>监护人:{{ item.fname }}</div>
                  <div>{{ item.mobile }}</div>
                </div>
              </div>
            </div>
          </div>
        </van-list></van-tab
      >
    </van-tabs>
    <van-popup
      v-model="isShow"
      closeable
      close-icon="close"
      :style="{ height: '30%', width: '90%' }"
    >
      <div class="popup-content-box">
        <div class="price-btn">
          <van-radio-group
            v-model="radio"
            direction="horizontal"
            @change="changeRadio"
          >
            <van-radio
              :name="item.fee_item_id"
              icon-size="24px"
              v-for="item in curPassList"
              :key="item.fee_item_id"
              >{{ item.item_name }}</van-radio
            >
          </van-radio-group>
        </div>
        <div class="price-text">
          <van-field
            label="金额"
            v-model="total_amount"
            size="large"
            type="digit"
            border
            placeholder="请输入金钱"
            right-icon="edit"
            @input="changeAmount"
          />
        </div>
      </div>
      <div class="popup-content-btn">
        <van-button block type="info" @click="subPrice(3, '')">确定</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getData, handleStuInfoPrice } from "@/api/chooseTap";
export default {
  name: "ReportIndex",
  data() {
    return {
      status: "2",
      project_id: "710",
      page: 1,
      pagesize: 10,
      list: [],
      curPassList: [],
      loading: false,
      finished: false,
      total_amount: "",
      total: 0,
      isShow: false,
      radio: "1",
      currentIndex: 0,
    };
  },
  /* mounted() {
    this.getData();
  }, */
  methods: {
    /* 获取数据 */
    getData() {
      let info = {
        status: this.status,
        project_id: "710",
        page: this.page,
        pagesize: this.pagesize,
      };
      let data = {
        request: JSON.stringify(info),
      };
      getData(data).then((res) => {
        this.list = this.list.concat(res.data.list);
        this.loading = false;
        this.page++;
        this.total = res.data.total;
        this.list.length >= this.total && (this.finished = true);
      });
    },
    /* 弹出层 */
    showPopup(index) {
      this.currentIndex = index;
      this.curPassList = this.list[index]["fee_item_list"];
      this.radio = this.list[index]["fee_item_list"][0]["fee_item_id"];
      this.total_amount = this.list[index]["fee_item_list"][0]["value"];
      this.isShow = true;
    },
    /* 切换tab */
    changeTabs() {
      this.page = 1;
      this.list = [];
      this.getData();
    },
    /* 切换价格方式 */
    changeRadio(val) {
      this.list[this.currentIndex]["fee_item_list"].map((item) => {
        if (item["fee_item_id"] == val) {
          this.total_amount = item["value"];
        }
      });
    },
    /* 改变价格 */
    changeAmount(value) {
      this.list[this.currentIndex]["fee_item_list"].forEach((item) => {
        if (item["fee_item_id"] == this.radio) {
          item["value"] = value;
        }
      });
    },
    /* 提交价格 */
    subPrice(btnIndex, index) {
      index && (this.currentIndex = index);
      this.list[this.currentIndex]["fee_item_list"].forEach((item) => {
        if (!(item["fee_item_id"] == this.radio)) {
          item["value"] = 0;
        }
      });
      this.isShow = false;
      let info = {
        status: btnIndex,
        project_id: this.list[this.currentIndex]["project_id"],
        school_id: JSON.parse(localStorage.getItem("header")).school_id,
        student_id: this.list[this.currentIndex]["student_id"],
        fee_items: this.list[this.currentIndex]["fee_item_list"],
        total_amount: this.total_amount,
      };
      let data = {
        request: JSON.stringify(info),
      };
      handleStuInfoPrice(data).then((res) => {
        if (res.code == 0) {
          this.page = 1;
          this.list = [];
          this.getData();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.outer-box {
  width: 100%;
  padding: 0.1rem 0.2rem;
  box-sizing: border-box;
  .info-box {
    width: 100%;
    font-size: 0.32rem;
    padding: 0.2rem 0.2rem;
    border: 1px solid #e5e5e5;
    box-shadow: 0 0 0.1rem 0.01rem #e5e5e5;
    box-sizing: border-box;
    .info-content {
      div {
        margin: 0.1rem 0 0;
      }
      .info-box-one {
        display: flex;
        div {
          display: flex;
        }
        div:nth-child(1) {
          min-width: 33.3%;
        }
        div:nth-child(2) {
          flex: 1;
          justify-content: center;
        }
        div:nth-child(3) {
          flex: 1;
          justify-content: center;
        }
      }
      .info-box-two {
        display: flex;
        justify-content: space-between;
      }
      .info-box-three {
        div {
          margin: 0.15rem 0 0;
        }
      }
      .info-box-four {
        display: flex;
        div {
          flex: 1;
          margin: 0.15rem 0 0;
        }
      }
    }

    .info-btn {
      width: 100%;
      margin-top: 0.2rem;
      display: flex;
      .van-button {
        margin: 0 0.2rem !important;
      }
    }
  }
}
/* 弹框 */
.popup-content-box {
  margin-top: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .price-btn {
    display: flex;
    justify-content: space-around;
  }
  .price-text {
    margin-top: 0.3rem;
    font-size: 0.4rem;
    width: 84%;
    display: flex;
    justify-content: space-between;
    border: 0.02rem solid #dedede;
  }
}
.popup-content-btn {
  justify-content: center;
  display: flex;
  margin-top: 0.3rem;
}
</style>
