<template>
  <div v-cloak>
    <div class="img-box">
      <img src="../../assets/img/huamei.jpg" alt="" />
    </div>
    <div class="main">
      <div class="box">
        <span class="box_label">手机号：</span>
        <input
          type="text"
          autocomplete="username"
          placeholder="请输入手机号"
          v-model="username"
        />
      </div>

      <div class="box">
        <span class="box_label">密码：</span>
        <input
          type="password"
          autocomplete="current-password"
          placeholder="请输入密码"
          v-model="user_password"
        />
      </div>
      <a
        href="javascript:;"
        rel="noopener noreferrer"
        @click="toForgetPassword"
      >
        忘记密码?</a
      >
      <div class="btn-box">
        <div class="btn-reportinfo" @click="toRegisterPage">注册</div>
        <div class="btn-login" @click="toLogin">登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getProjectInfo, getLogin } from "@/api/login.js";
export default {
  name: "LoginIndex",
  data() {
    return {
      username: "",
      user_password: "",
      project_hash: "",
      projectInfo: "",
    };
  },
  mounted() {
    localStorage.removeItem("header");
    /* this.getUrlParams().then(() => {
      this.getProjectInfo();
    }); */
    this.getbrowertype().then(() => {
      this.getUrlParams().then(() => {
        this.getProjectInfo();
      });
    });
  },
  methods: {
    /* 判断是微信还是支付宝 */
    getbrowertype() {
      return new Promise((resolve) => {
        var ua = navigator.userAgent.toLowerCase();
        if (
          !(
            ua.match(/MicroMessenger/i) == "micromessenger" ||
            ua.match(/Alipay/i) == "alipay"
          )
        ) {
          Toast("请使用微信或支付宝打开");
        } else {
          resolve();
        }
      });
    },
    /* 获取页面数据 */
    getUrlParams() {
      return new Promise((resolve) => {
        let url_string = window.location.href; //window.location.href
        let url = new URL(url_string);
        let project_hash = url.searchParams.get("project_hash");
        this.project_hash = project_hash;
        resolve();
      });
    },
    /* 获取项目信息 */
    getProjectInfo() {
      let data = {
        request: JSON.stringify({
          project_hash: this.project_hash,
        }),
      };
      getProjectInfo(data).then((res) => {
        if (res.code == 0) {
          this.projectInfo = res.data;
          localStorage.setItem("projectInfo", JSON.stringify(res.data));
        }
      });
    },
    /* 注册 */
    toRegisterPage() {
      this.$router.push({ path: "/register", query: { action: "register" } });
    },
    /* 忘记密码 */
    toForgetPassword() {
      this.$router.push({ path: "/register", query: { action: "reset" } });
    },
    /* 登录 */
    toLogin() {
      let info = {
        mobile: this.username,
        passwd: this.user_password,
        project_id: JSON.parse(localStorage.getItem("projectInfo")).project_id,
      };
      let data = {
        request: JSON.stringify(info),
      };
      getLogin(data).then((res) => {
        let data = res.data;
        if (res.code == 0) {
          let url = "/report";
          localStorage.setItem("header", JSON.stringify(res.data));
          if (data.status < 2) {
            // this.$router.push("/report");
            url = "report";
          } else if (data.status == 2) {
            // this.$router.push("/review");
            url = "/review";
          } else if (data.status > 2) {
            // this.$router.push("/topay");
            url = "/topay";
          }
          if (data.status == 6) {
            url = "/review";
          }
          this.$router.push({ path: url });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  margin-top: 0.6rem;
  padding: 0 0.45rem;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 0.1rem;
}
.img-box {
  width: 100%;
  height: 20%;
  img {
    width: 100%;
    height: 100%;
  }
}

.box {
  width: 100%;
  height: 1rem;
  border: 1px solid rgba(226, 226, 226, 1);
  margin: 0 auto;
  overflow: hidden;
  margin-bottom: 0.3rem;
  padding: 0 0.2rem;
  display: flex;
  box-sizing: border-box;
}

.box_label {
  width: 1.7rem;
  font-size: 0.32rem;
  color: rgba(51, 51, 51, 1);
  line-height: 1rem;
}

.box input {
  flex: 1;
  height: 1rem;
  line-height: 1rem;
  border: 0;
  padding-right: 0.2rem;
  font-size: 0.32rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}

.btn-box {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
}

.btn-login {
  width: 3rem;
  height: 0.88rem;
  background-color: #2ab1f3;
  border-radius: 0.1rem;
  text-align: center;
  line-height: 0.88rem;
  font-size: 0.36rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}

.btn-reportinfo {
  width: 3rem;
  height: 0.88rem;
  background-color: #09bb07;
  border-radius: 0.1rem;
  text-align: center;
  line-height: 0.88rem;
  font-size: 0.36rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}
</style>
