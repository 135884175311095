import request from "@/utils/request.js";

// 获取配置信息
 const get_config_info = (data) => {
  return request({
    url: "/hd.yinshenghd.configinfo",
    method: "post",
    data: data,
  });
};

//登录
 const get_login = (data) => {
  return request({
    url: "/hd.yinshenghd.login",
    method: "post",
    data: data,
  });
};

//支付参数
 const get_pay_data = (data) => {
  return request({
    url: "/hd.yinshenghd.getpayparams",
    method: "post",
    data: data,
  });
};

export {
	get_config_info,
	get_login,
  get_pay_data
}