import request from '@/utils/request.js'
/* 获取城市信息接口 */
const getCityInfo = (data) => {
	return request({
		url: '/h5.index.student.info',
		method: 'post',
		data: data
	})
}
/* 获取学校信息 */
const getSchoolInfo = (data) => {
	return request({
		url: '/h5.index.student.school.list',
		method: 'post',
		data: data
	})
}
/* 提交信息 */
const submitStuInfo = (data) => {
	return request({
		url: '/h5.index.student.submit',
		method: 'post',
		data: data
	})
}
/* 查询录取码 */
const checkCodeAPI = (data) => {
	return request({
		url: '/h5.project.querycode',
		method: 'post',
		data: data
	})
}
export {
	getCityInfo,
	getSchoolInfo,
	submitStuInfo,
	checkCodeAPI
}