<template>
  <div v-cloak>
      
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "zhuangzhuan",
  data() {
    return {
      gender: "1",
      show: false,
      fieldValue: "",
      sutdentData: {},
      timeFlag: true,
      timeTamp: null,
      params:{}
    };
  },
  mounted() {
    this.setInfo();
  },
  methods: {
    //http://192.168.31.230:8080/zhongzhuan?qrcode_sn=qrcode3e7bb29ec2392c8cbc582580b0b6b685&uri=http://group.h5.fangaoyun.com&wxappid=wx53b07c0778b53460&aliappid=2018040202488269
    
    /*初始化信息*/
    setInfo(){
        let type = this.getbrowertype();
        if(type == 0){  Toast('请使用支付宝或微信打开');  return;  }
        let params = this.getUrlParams();
        var jump_url = '';
        if(params.code){
          let first_params = localStorage.getItem('first_params');
          first_params = JSON.parse(first_params);
          jump_url = first_params.uri + '?qrcode_sn=' + first_params.qrcode_sn + '&code=' + params.code;
        }else{
            localStorage.setItem('first_params',JSON.stringify(params));
            if(type == 1){
                jump_url = this.getwxAuthUrl(params);
            }else{
                jump_url = this.getalipayAuthUrl(params);
            }
            
        }
        //alert(jump_url);alert(type);
        console.log(jump_url);
        window.location.href = jump_url;
        
    },

    getwxAuthUrl(params){
        let url = 'https://open.weixin.qq.com/connect/oauth2/authorize';
        url += '?appid=' + params['wxappid'];
        url += '&redirect_uri=http://h5.pay.fangaoyun.com/zhongzhuan';
        url += '&response_type=code';
        url += '&scope=snsapi_base';
        url += '&state=STATE#wechat_redirect';
        return url;
    },
    getalipayAuthUrl(params){
        let url = 'https://openauth.alipay.com/oauth2/publicAppAuthorize.htm';
        url += '?app_id=' + params['aliappid'];
        url += '&scope=auth_base';
        url += '&redirect_uri=http://h5.pay.fangaoyun.com/zhongzhuan';
        return url ;
    },

    /* 判断是微信还是支付宝 */
    getbrowertype() {
        var ua = window.navigator.userAgent;
        //alert(ua);
        var type = 0;
        //判断是不是微信
        if (ua.indexOf("MicroMessenger") > 0) { type = 1; }
        //判断是不是支付宝
        if (ua.indexOf("AlipayClient") > 0) { type = 2; }
        return type;

    },

    /*获取url参数*/
    getUrlParams() {
       let url_string = window.location.href; //window.location.href
        let url = new URL(url_string);
        let qrcode_sn = url.searchParams.get('qrcode_sn');
        let uri = url.searchParams.get('uri');
        let wxappid = url.searchParams.get('wxappid');
        let aliappid = url.searchParams.get('aliappid');
        let code = url.searchParams.get('code');
        let auth_code = url.searchParams.get('auth_code');
        if(auth_code){
          code = auth_code ;
        }
        let data = { 
          qrcode_sn:qrcode_sn,
          uri:uri,
          wxappid:wxappid,
          aliappid:aliappid,
          code:code,
        }

        return data;
    },



  }
};
</script>

<style lang="less" scoped>

</style>
