<template>
  <div class="content-box">
    <div class="banner-box" v-if="projectInfo.h5_banner">
      <img :src="projectInfo.h5_banner" />
    </div>
    <!-- 
    <div class="base_form">
      <el-form :model="form" label-width="auto">
        <el-form-item
          label="用户名"
          :rules="[{ required: true, message: '请输入手机号' }]"
        >
          <el-input v-model="mobile" placeholder="请输入手机号" />
        </el-form-item>
      </el-form>
    </div> -->

    <div class="login-box">
      <div class="custom_form">
        <form-create v-model="fapi" :rule="rule" :option="option"></form-create>
      </div>

      <van-cell-group>
        <van-field v-model="mobile" label="用户名" placeholder="请输入手机号" />
        <van-field
          v-model="passwd"
          type="password"
          label="密码"
          placeholder="请输入密码"
        />
        <van-field
          v-model="passwd2"
          type="password"
          label="重复密码"
          placeholder="请输入重复密码"
        />
      </van-cell-group>
      <div class="imgcode-box">
        <div class="filed-box">
          <van-field
            v-model="image_code"
            label="验证码"
            placeholder="请输入验证码"
          />
        </div>
        <div class="img-box" @click="getCodeImg">
          <img :src="src" />
        </div>
      </div>
      <div class="span-box">
        <span>看不清，点击图片刷新</span>
      </div>
      <van-cell-group v-if="projectInfo.verify_sms_code == 1">
        <van-field
          v-model="verify_code"
          maxlength="6"
          center
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              @click="sendCode"
              v-if="isSend"
            >
              发送验证码
            </van-button>
            <div class="timer" v-else>{{ timer }}s</div>
          </template>
        </van-field>
      </van-cell-group>

      <div class="btn-box">
        <van-button
          type="info"
          block
          :disabled="btnDisabled"
          :loading="btnLoading"
          loading-text="请稍等..."
          @click="toRegist"
        >
          {{ btn_text }}
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { toRegist, sendCode } from "@/api/standard/registLogin.js";
import { registerForm } from "@/api/standard/onlyLogin.js";

import configopenid from "@/utils/wxconfig.js";

import OSS from "ali-oss";
const client = new OSS({
  region: "oss-cn-beijing", // 地域（在创建 Bucket 的时候指定的中心位置），这里可能不知道具体地域怎么填其实就是 oss-cn-中心位置 ，例：region:'oss-cn-chengdu'，chengdu则是创建bucket是指定的位置成都。
  accessKeyId: "LTAI4GEeU3VjmHAWP7GMvhAg", // 阿里云产品的通用id
  accessKeySecret: "URUiu1qeGwBcD6D8Tzd70RFJ0Mrx5s", // 密钥
  bucket: "fangaocloud", // OSS 存储区域名
});
var moment = require("moment"); // require
let oss_date = moment().format("YYYY-MM-DD");
import city from "@/utils/city.js";

export default {
  name: "OnlyLoginIndex",
  data() {
    return {
      form: {
        mobile: "",
        passwd: "",
        passwd2: "",
        verify_code: "",
        image_code: "",
      },

      // mobile: "",
      // passwd: "",
      // passwd2: "",
      // verify_code: "",
      // image_code: "",
      // projectInfo: {},
      // src: "",
      // image_id: "",
      // isSend: true,
      // timer: 60,
      // btn_text: "注册",
      // btnLoading: false,
      // btnDisabled: false,

      // mobile: "15731471786",
      // passwd: "123456",
      // passwd2: "123456",
      mobile: "",
      passwd: "",
      passwd2: "",
      verify_code: "",
      image_code: "",
      projectInfo: {},
      src: "",
      image_id: "",
      isSend: true,
      timer: 60,
      btn_text: "注册",
      btnLoading: false,
      btnDisabled: false,

      // 自定义表单相关数据start
      fapi: null,
      rule: [],
      option: {},
      formInfo: {},
      item_json: [
        {
          value: "",
          options: {
            value: ["", ""],
          },
        },
      ],
      payment_type_ext: localStorage.getItem("payment_type_ext"), //运行环境
      code: "0714NHkl2vAJd94pTpnl2KYFOA14NHkq",
      paydata: "",
      // 自定义表单相关数据end
    };
  },
  mounted() {
    // let flag = this.$route.query.flag;
    // flag === "regist" ? (this.btn_text = "注册") : (this.btn_text = "重置密码");
    let projectInfo = localStorage.getItem("projectInfo");
    this.projectInfo = JSON.parse(projectInfo);
    this.getCodeImg();

    // 获取自定义表单信息start
    // let headerInfo = JSON.parse(localStorage.getItem("headerInfo"));
    let payment_type_ext = localStorage.getItem("payment_type_ext");
    this.payment_type_ext = payment_type_ext ? payment_type_ext : "alipay";
    // this.headerInfo = headerInfo;
    this.paydata = JSON.parse(localStorage.getItem("paydata")) || "";
    this.getFormInfo();
    // 获取自定义表单信息end
  },
  methods: {
    /* 获取图形码 */
    getCodeImg() {
      console.log("获取图形码");
      let tmp = Math.floor(Math.random() * (100000 - 0)) + 0;
      let dateTmp = new Date().getTime();
      this.image_id = `${this.projectInfo.project_id}${tmp}${dateTmp}`;
      let dommin = configopenid.isline
        ? configopenid.onlineimgurl
        : configopenid.devimgUrl;
      this.src = `${dommin}/h5.index.project.sendVerifyImage?id=${this.projectInfo.project_id}${tmp}${dateTmp}`;
    },
    /* 倒计时 */
    countDown() {
      this.timer = 60;
      let timer = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          clearInterval(timer);
          this.isSend = true;
        }
      }, 1000);
    },
    /* 发送验证码 */
    sendCode() {
      console.log("发送验证码");
      // let flag = this.$route.query.flag;
      if (this.mobile == "") {
        Toast("请输入手机号");
        return false;
      }
      if (this.image_code == "") {
        Toast("请输入图形验证码");
        return false;
      }
      let info = {
        mobile: this.mobile,
        image_code: this.image_code,
        image_id: this.image_id,
        project_id: this.projectInfo.project_id,
        action: "regist",
      };
      let data = {
        request: JSON.stringify(info),
      };
      sendCode(data).then((res) => {
        console.log("验证码", res);
        if (res.code == 0) {
          this.isSend = false;
          this.countDown();
        } else {
          Toast(res.msg);
          this.getCodeImg();
        }
      });
    },
    /* 注册||重置 */
    toRegist() {
      this.btnLoading = true;
      this.btnDisabled = true;
      console.log("注册", this.$route.query);
      let option = this.option;
      option.global = {};

      let info = {
        project_id: this.projectInfo.project_id,
        mobile: this.mobile,
        verify_code: this.verify_code,
        passwd: this.passwd,
        passwd2: this.passwd2,
        action: "regist",
        image_id: this.image_id,
        image_code: this.image_code,
        register_submit_json: {
          FcDesignerRule: this.rule,
          FcDesignerOptions: option,
        },
      };
      let data = {
        request: JSON.stringify(info),
      };
      toRegist(data)
        .then((res) => {
          console.log("注册成功", res);
          this.btnLoading = false;
          this.btnDisabled = false;
          if (res.code == 0) {
            localStorage.setItem("headerInfo", JSON.stringify(res.data));
            let url = "/onlyLoginForm";
            this.$router.push(url);
          } else {
            Toast(res.msg);
            this.getCodeImg();
          }
        })
        .catch((err) => {
          console.log("注册失败", err);
          this.btnLoading = false;
          this.btnDisabled = false;
        });
    },

    /* 请求表单信息 */
    getFormInfo() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      let info = {
        project_id: this.projectInfo.project_id,
        // student_id: this.headerInfo.student_id,
      };
      let data = {
        request: JSON.stringify(info),
      };
      let headers = { token: "this.headerInfo.token" };
      registerForm(headers, data).then((res) => {
        Toast.clear();
        let data = res.data;
        this.formInfo = data;
        //项目收费规则
        let item_json = data.project_items_json;
        this.item_json = item_json;
        //自定义表单
        let template_json = data.template_json;
        let FcDesignerRule = template_json.FcDesignerRule;
        this.rule = FcDesignerRule;
        let option = template_json.FcDesignerOptions;
        console.log("自定义表单 ------------------> ", option);
        option.preview = true;
        option.global = {
          // 上传组件公共配置
          upload: {
            props: {
              httpRequest: (param) => {
                console.log("httpRequest1", param);
                let fileType = param.file.name.split(".").pop();
                client
                  .put(
                    `fangaopayment/${oss_date}/${new Date().getTime()}_${parseInt(
                      Math.random() * 100000000
                    )}.${fileType}`,
                    param.file
                  )
                  .then((res) => {
                    param.file.url = res.url;
                    param.onSuccess(res, param.file);
                    console.log(res);
                  });
              },
              onSuccess: (res, file) => {
                console.log("上传的图片123", res, file);
                file.url = res.url;
              },
              // 'custom-request': (options) => {
              //     console.log('options', options);
              // },
              // 上传成功回调
              // onSuccess: (response, file) => {
              //   console.log('=====', response, file)
              //   file.url = response.data
              // },
            },
          },
          cascader: {
            props: {
              options: city,
            },
          },
        };
        // this.option = template_json.FcDesignerOptions;
        console.log("自定义表单option", option);
        this.option = option;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .banner-box {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .base_form {
    background: #fff;
    margin: 0.2rem 0.2rem;
    padding: 10px 16px;

    /deep/ .el-form-item__label-wrap {
      float: none;
    }

    /deep/ .el-form-item__content {
      margin-left: 0 !important;
    }
  }
  .login-box {
    width: 100%;
    padding: 0.2rem 0.2rem;
    box-sizing: border-box;
    .imgcode-box {
      display: flex;
      .img-box {
        flex: 1;
        height: 1rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .filed-box {
        width: 2rem;
        flex: 1 1 2rem;
        height: 1rem;
        van-field {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .span-box {
    text-align: right;
    color: #0073ff;
    font-size: 12px;
    padding-top: 10px;
  }
  .btn-box {
    margin-top: 0.2rem;
    .van-button {
      width: 100%;
    }
  }
  .timer {
    width: 2rem;
    text-align: center;
  }
  .custom_form {
    background: #fff;
    padding: 10px 16px;
    margin-top: 0.2rem;
  }
}
</style>
