import request from '@/utils/request_base.js'
/* 注册 */
const toRegist = (data) => {
	return request({
		url: '/h5.index.project.standregister',
		method: 'post',
		data
	})
}
/* 手机验证码 */
const sendCode = (data) => {
	return request({
		url: '/h5.index.project.sendtelsms',
		method: 'post',
		data
	})
}
/* 登录 */
const toLogin = (data) => {
	return request({
		url: '/h5.index.project.standlogin',
		method: 'post',
		data
	})
}
/* 重置 */
const toReset = (data) => {
	return request({
		url: '/h5.index.project.resetpasswd',
		method: 'post',
		data
	})
}
export {
	toReset,
	toRegist,
	sendCode,
	toLogin
}