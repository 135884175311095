<template>
  <div>
    <div class="box">
      <div class="school_name">{{ config_code_list.name }}</div>
      <img v-if="img_url != ''" class="school_img" :src="img_url" alt="" />
    </div>
    <div class="box">
      <div style="font-size: 14px">请选择交费项</div>
      <div class="line"></div>

      <div class="sel_box">
        <div>
          已选<span style="color: red">{{ length_list }}</span
          >项
        </div>
        <div>
          合计:<span style="color: red">￥{{ money_list }}</span>
        </div>
      </div>

      <div class="content">
        <div v-for="(item, index) in cateList" :key="index">
          <div v-if="item.is_hidden == 1" style="margin-top: 10px">
            <div class="head">
              <div class="title">
                <span style="padding-right: 5px">{{ item.cate_name }}</span>
                <van-tag plain type="primary">{{
                  item.other_setting == 1 ? "必交" : "选交"
                }}</van-tag>
              </div>
              <div style="color: #9ca3af; font-size: 12px">
                ({{ item.number == 0 ? "不限" : item.number }})
              </div>
            </div>
            <div class="head">
              <van-checkbox
                v-model="item.checked"
                :disabled="item.disabled"
                @click="checkboxChange(item, index)"
              ></van-checkbox>
              <van-field
                :ref="'inputRef' + index"
                class="int"
                placeholder="请输入交费金额"
                v-model="item.amount"
                :readonly="item.charge_way == 2 ? true : false"
                @click="changeMoney(item)"
                @blur="totalMoney"
              ></van-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <div style="font-size: 14px">信息登记</div>
      <div class="line" style="margin-bottom: 20px"></div>
      <div v-for="(item, index) in formList" :key="index">
        <van-field
          v-if="item.type == 'input'"
          v-model="item.info"
          style="border-radius: 5px; margin: 10px auto"
          :label="item.title"
          :placeholder="`请输入${item.title}`"
        />
        <van-field v-if="item.type == 'radio'" :label="item.title">
          <template #input>
            <van-radio-group v-model="item.info" direction="horizontal">
              <van-radio name="1">男</van-radio>
              <van-radio name="2">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
      </div>
    </div>
    <div
      style="
        text-align: center;
        color: #1677ff;
        font-size: 12px;
        padding: 10px 0;
      "
    >
      查看历史缴费记录
    </div>
    <div style="width: 95%; margin: 0 auto">
      <button class="btn" @click="payMoney">提交</button>
    </div>
  </div>
</template>
  
  <script>
import { get_config_info, get_pay_data } from "@/api/code.js";
import { Toast, Dialog } from "vant";

export default {
  data() {
    return {
      checked: [],
      REDIRECT_URI: encodeURIComponent("http://h5.pay.fangaoyun.com/h5code"),
      SCOPE: "snsapi_userinfo",
      hash: "",
      appid: "",
      service_provider: "",
      plat_form: "",
      cateList: [],
      img_url: "",
      config_code_list: "",
      username: "",
      length_list: 0,
      money_list: 0,
      formList: {},
    };
  },
  mounted() {
    this.hash = localStorage.getItem("hash") || "";
    this.getConfigInfo();
  },
  methods: {
    //获取配置信息
    getConfigInfo() {
      const data = {
        request: JSON.stringify({
          hash: this.hash,
        }),
      };
      get_config_info(data).then((res) => {
        if (res.code != 0) {
          return Toast.fail(res.msg);
        }
        if (res.data.cate_list) {
          res.data.cate_list.forEach((val) => {
            val.amount = val.amount == "0.00" ? "" : val.amount;
            val.checked = false;
            val.disabled = false;
            if (val.other_setting == 1) {
              val.checked = true;
              val.disabled = true;
              this.length_list += 1;
              this.money_list += Number(val.amount);
            }
          });
        }
        this.cateList = res.data.cate_list;
        this.config_code_list = res.data.codeInfo;
        this.service_provider = res.data.service_config.id;
        localStorage.setItem("plat_form", res.data.codeInfo.pay_status);
        localStorage.setItem("service_provider", res.data.service_config.id);
        this.appid = res.data.service_config.appid;
        this.plat_form = res.data.codeInfo.pay_status; //1:微信 2：支付宝 0：均可
        this.img_url = res.data.codeInfo.header_url;
        this.formList = res.data.form
          ? JSON.parse(res.data.form.template_json).FcDesignerRule
          : "";
      });
    },
    changeMoney(val) {
      val.checked = true;
    },
    totalMoney() {
      this.money_list = 0;
      this.length_list = 0;
      this.cateList.forEach((res) => {
        this.length_list += 1;
        this.money_list += Number(res.amount);
      });
    },
    checkboxChange(val, index) {
      this.money_list = 0;
      this.length_list = 0;
      this.$refs["inputRef" + index][0].focus();
      console.log(val, val.checked, "val====");
      this.cateList.forEach((res) => {
        if (res.checked == true) {
          this.length_list += 1;
          this.money_list += Number(res.amount);
        }
      });
    },
    payMoney() {
      if (this.money_list == 0) {
        Dialog.alert({
          title: "错误提示",
          message:
            "支付总额需要大于0元,才可进行支付，请您核对所选缴费项目是否正确。",
          confirmButtonText: "知道了",
          showCancelButton: false,
          confirmButtonColor: "#3B82F6",
        });
      }
      const show = this.checkList();
      console.log(show, "show====");
      if (show == true) {
        this.cateList.forEach((item) => {
          if (item.amount == "") {
            item.amount = 0;
          }
        });
        const data = {
          request: JSON.stringify({
            hash: localStorage.getItem("hash"),
            service_provider: localStorage.getItem("service_provider"),
            plat_form: localStorage.getItem("plat_form"),
            cate_list: this.cateList,
            form_list: this.formList,
          }),
        };
        get_pay_data(data).then((res) => {
          if (res.code != 0) {
            return Toast.fail(res.msg);
          }
          this.wxpayClick(res.data);
        });
      }
    },
    checkList() {
      const arr = this.cateList;
      const form_list = this.formList;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].checked == true && arr[i].amount == "") {
          Dialog.alert({
            title: "错误提示",
            message: `请填写【${arr[i].cate_name}】进行支付`,
            confirmButtonText: "知道了",
            showCancelButton: false,
            confirmButtonColor: "#3B82F6",
          });
          return false;
        }
      }
      for (let i = 0; i < form_list.length; i++) {
        if (form_list[i].info == "" && form_list[i].effect.required == true) {
          Dialog.alert({
            title: "错误提示",
            message: `请填写【${form_list[i].title}】`,
            confirmButtonText: "知道了",
            showCancelButton: false,
            confirmButtonColor: "#3B82F6",
          });
          return false;
        }
      }
      return true;
    },
    //微信支付
    wxpayClick(val) {
      console.log(val, "微信支付");
      const data = {
        appId: val.appId, //公众号ID，由商户传入
        timeStamp: val.timeStamp, //时间戳，自1970年以来的秒数
        nonceStr: val.nonceStr, //随机串
        package: val.package,
        signType: val.signType, //微信签名方式：
        paySign: val.paySign, //微信签名
      };
      window.WeixinJSBridge.invoke("getBrandWCPayRequest", data, (res) => {
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          Toast.success("支付成功");
          // this.$router.push("/pay_success"); //支付成功的页面
        }
      });
    },
  },
};
</script>
  
  <style scoped>
.box {
  background-color: #fff;
  border-radius: 10px;
  margin: 10px;
  padding: 15px;
}
.school_name {
  font-size: 20px;
  color: #333;
  text-align: center;
  font-weight: bold;
  margin-bottom: 15px;
}
.school_img {
  width: 100%;
  height: 100%;
}
.line {
  width: 80px;
  height: 5px;
  background: linear-gradient(to right, blue, #fff);
  margin-top: 5px;
}
.content {
  padding: 10px;
  border-radius: 5px;
}
.title {
  font-size: 14px;
  color: #111827;
  padding: 0 20px;
  margin-left: 15px;
}
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px auto;
}
.int {
  width: 270px;
  border-radius: 5px;
}
.btn {
  width: 100%;
  height: 48px;
  background: #1677ff;
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 50px;
  margin-bottom: 10px;
}
.van-cell {
  background-color: #f4f4f4;
}
.sel_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
}
.parent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
}
</style>