<template>
  <div v-cloak>
    <div class="img-box">
      <img src="../../assets/img/huamei.jpg" alt="" />
    </div>
    <div class="main">
      <div class="box">
        <span class="box_label">手机号：</span>
        <input
          type="number"
          autocomplete="username"
          placeholder="请输入手机号"
          v-model="username"
        />
      </div>

      <div class="box">
        <span class="box_label">密码：</span>
        <input
          type="password"
          autocomplete="current-password"
          placeholder="请输入密码"
          v-model="user_password"
        />
      </div>
      <div class="btn-box">
        <div class="btn-login" @click="toChooseItem">登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTeacherLogin } from '@/api/login.js'
import { Toast } from 'vant'
export default {
  name: 'LoginIndex',
  data() {
    return {
      username: '',
      user_password: '',
    }
  },
  mounted() {
    localStorage.removeItem('header')
  },
  methods: {
    /* 登录 */
    toChooseItem() {
      if (!this.username) {
        Toast('请输入手机号')
        return
      }
      if (!this.user_password) {
        Toast('请输入密码')
        return
      }
      let info = { mobile: this.username, passwd: this.user_password }
      let data = {
        request: JSON.stringify(info),
      }
      getTeacherLogin(data).then((res) => {
        if (res.code == 0) {
          localStorage.setItem('header', JSON.stringify(res.data))
          this.$router.push('/chooseTap')
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  margin-top: 0.6rem;
  padding: 0 0.45rem;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 0.1rem;
}
.img-box {
  width: 100%;
  height: 20%;
  img {
    width: 100%;
    height: 100%;
  }
}

.box {
  width: 100%;
  height: 1rem;
  border: 1px solid rgba(226, 226, 226, 1);
  margin: 0 auto;
  overflow: hidden;
  margin-bottom: 0.3rem;
  padding: 0 0.2rem;
  display: flex;
  box-sizing: border-box;
}

.box_label {
  width: 1.7rem;
  font-size: 0.32rem;
  color: rgba(51, 51, 51, 1);
  line-height: 1rem;
}

.box input {
  flex: 1;
  height: 1rem;
  line-height: 1rem;
  border: 0;
  padding-right: 0.2rem;
  font-size: 0.32rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}

.btn-box {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.btn-login {
  width: 3rem;
  height: 0.88rem;
  background-color: #2ab1f3;
  border-radius: 0.1rem;
  text-align: center;
  line-height: 0.88rem;
  font-size: 0.36rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}

.btn-reportinfo {
  width: 3rem;
  height: 0.88rem;
  background-color: #09bb07;
  border-radius: 0.1rem;
  text-align: center;
  line-height: 0.88rem;
  font-size: 0.36rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}
</style>
