<template>
  <div class="outer-box">
    <div class="banner-box" v-if="projectInfo.h5_banner">
      <img :src="projectInfo.h5_banner" />
    </div>

    <div class="title">当前学生信息</div>
    <div class="school_box">
      <div class="sex_box">
        <div class="student">{{ stuInfo.name }}</div>
        <div class="student">{{ stuInfo.gender == 1 ? "男" : "女" }}</div>
        <div class="student">{{ stuInfo.mobile }}</div>
      </div>
      <div class="sex_box">
        <div class="txt">{{ stuInfo.finish_school }}</div>
        <div class="txt">
          {{ stuInfo.last_score ? stuInfo.last_score : stuInfo.sum_total }}分
        </div>
      </div>
    </div>
    <!-- <div class="info-box">
      <div>{{ stuInfo.text }}</div>
      <div>{{ stuInfo.total_amount }}</div>
    </div>
    <div class="sub-box" v-if="stuInfo.status == 3">
      <van-button type="info" block @click="getPayInfo">支付</van-button>
    </div> -->
    <div class="info-box">
      <div class="money-box">
        <div class="pay-img">
          <div class="img-box">
            <img src="../../../assets/img/topay.png" />
          </div>
          <span>{{ stuInfo.text }}</span>
        </div>
        <span v-if="stuInfo.total_amount * 1 > 0">
          {{ stuInfo.total_amount }}元
        </span>
      </div>
    </div>
    <div class="btn-box">
      <div class="sub-box" v-if="stuInfo.status == 3">
        <van-button type="info" round block @click="getPayInfo">
          支付
        </van-button>
      </div>
    </div>
    <div id="alipayhtml" v-html="html"></div>
    <div v-if="configopenid.debug && !configopenid.isline">
      <div>payment_type_ext：{{ payment_type_ext }}</div>
      ======
      <div>projectInfo：{{ projectInfo }}</div>
      ======
      <div>headerInfo：{{ headerInfo }}</div>
      ======
      <div>paydata：{{ paydata }}</div>
      ======
      <div>requestData：{{ requestData }}</div>
      ======
      <div>res：{{ res }}</div>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from "@/api/standard/onlyLogin.js";
// import wx from "weixin-js-sdk";
import { getPayInfo } from "@/api/standard/common.js";
import { Toast } from "vant";
import configopenid from "@/utils/wxconfig.js";
export default {
  name: "PayIndex",
  data() {
    return {
      configopenid: configopenid,
      err: "",
      res: "",
      stuInfo: "",
      projectInfo: "",
      payment_type_ext: localStorage.getItem("payment_type_ext"),
      paydata: "",
      html: "",
    };
  },
  mounted() {
    let projectInfo = localStorage.getItem("projectInfo");
    let headerInfo = localStorage.getItem("headerInfo");
    let payment_type_ext = localStorage.getItem("payment_type_ext");
    this.payment_type_ext = payment_type_ext ? payment_type_ext : "alipay";
    this.projectInfo = JSON.parse(projectInfo);
    this.headerInfo = JSON.parse(headerInfo);
    this.paydata = JSON.parse(localStorage.getItem("paydata")) || "";
    this.getUserInfo();
  },
  methods: {
    /* 获取用户信息 */
    getUserInfo() {
      let headers = {
        token: this.headerInfo.token,
      };
      getUserInfo(headers).then((res) => {
        console.log("获取用户信息", res);
        if (res.code == 0) {
          this.stuInfo = res.data;
        }
      });
    },
    /* 微信支付 */
    onBridgeReady(pay_conf) {
      console.log(pay_conf);
      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: pay_conf.appId, //公众号ID，由商户传入
          timeStamp: pay_conf.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: pay_conf.nonceStr, //随机串
          package: pay_conf.package,
          signType: pay_conf.signType, //微信签名方式：
          paySign: pay_conf.sign_new, //微信签名
        },
        (res) => {
          console.log(res);
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            console.log("支付成功");
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          }
        }
      );
    },
    /* 获取支付参数并且拉起支付 */
    getPayInfo() {
      Toast.loading({
        forbidClick: true, // 禁用背景点击
        loadingType: "spinner",
        message: "加载中...",
      });
      let info = {
        total_amount: this.stuInfo.total_amount,
        student_id: this.stuInfo.student_id,
        project_id: this.projectInfo.project_id,
        pay_type: this.projectInfo.pay_type,
        payment_type_ext: this.payment_type_ext, //this.payment_type_ext,
        openid: this.paydata.openid || "",
        localAlipay: 1,
      };
      let data = {
        request: JSON.stringify(info),
      };
      let headers = {
        token: this.headerInfo.token,
      };
      this.requestData = data;
      getPayInfo(headers, data)
        .then((res) => {
          this.res = res;
          console.log("获取支付参数", res);
          if (res.code != 0) {
            setTimeout(() => {
              Toast.clear();
            }, 2000);
            return false;
          }
          Toast.clear();
          if (res.code == 0) {
            let pay_conf = res.data.pay_conf;

            if (pay_conf && pay_conf.qr_code) {
              location.href = pay_conf.qr_code;
              return;
            }

            if (pay_conf && pay_conf.code_url) {
              location.href = pay_conf.code_url;
              return;
            }

            console.log(pay_conf);
            if (pay_conf.payment_type == "zeropay") {
              location.reload();
              return;
            }
            
            if (pay_conf && pay_conf.payType === "yinshengv3") {
              location.href = pay_conf.pay_url;
              return;
            }

            if (pay_conf.payment_type == "alipaywap") {
              this.html = pay_conf.html;
              this.$nextTick(() => {
                document.forms["alipaysubmit"].submit();
              });
            }
            //微信内支付判断
            if (
              this.payment_type_ext == "wxpay" &&
              this.projectInfo.pay_type == "alipay"
            ) {
              Toast("请使用支付宝支付 ！");
              return false;
            }
            //支付宝内支付判断
            if (
              this.payment_type_ext == "alipay" &&
              this.projectInfo.pay_type == "wxpay"
            ) {
              Toast("请使用微信支付 ！");
              return false;
            }
            //微信内正常支付
            if (
              this.payment_type_ext == "wxpay" &&
              this.projectInfo.pay_type != "alipay"
              // (this.payment_type_ext == 'wxpay' &&
              //   this.projectInfo.pay_type == 'wxpay') ||
              // (this.projectInfo.pay_type == 'yinsheng' &&
              //   this.payment_type_ext == 'wxpay') ||
              // (this.projectInfo.pay_type == 'fuyou' &&
              //   this.payment_type_ext == 'wxpay')
            ) {
              let pay_conf = res.data.pay_conf;
              // this.weixinPay(pay_conf);
              this.onBridgeReady(pay_conf);
            }
            //支付宝内正常支付
            if (
              this.payment_type_ext == "alipay" &&
              this.projectInfo.pay_type == "alipay"
            ) {
              console.log("支付宝内正常支付", pay_conf);
              console.log(pay_conf.url);
              location.href = pay_conf.url;
              return;
            }
            if (
              this.payment_type_ext == "alipay" &&
              this.projectInfo.pay_type != "wxpay"
            ) {
              console.log("银盛支付宝支付");
              // location.href = pay_conf.url
              this.alipayBridgeReady(pay_conf.trade_no);
              return;
            }
            // this.weixinPay(pay_conf);
          }
        })
        .catch((err) => {
          console.log(err);
          this.err = err;
          Toast.clear();
        });
    },

    // 支付宝支付
    alipayBridgeReady(trandNo) {
      if (window.AlipayJSBridge) {
        window.AlipayJSBridge.call(
          "tradePay",
          {
            tradeNO: trandNo,
            // ... 支付参数 ...
          },
          function (result) {
            // 处理支付结果
            console.log("获取支付结果", result);
            window.AlipayJSBridge.call("exitApp");
          }
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
.outer-box {
  width: 100%;
  height: 100%;
  background: #fff;
  .banner-box {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .info-box {
    padding: 0.2rem 0.2rem;
    background: #fff;
    padding: 0.2rem 0.2rem;
    border-radius: 0.1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 0.6rem;
    box-sizing: border-box;
    .money-box {
      > span {
        font-size: 0.42rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
      }
    }
    > span {
      font-size: 0.26rem;
      margin-top: 0.26rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
  .btn-box {
    margin-top: 1rem;
  }
  .sub-box {
    padding: 0.2rem 0.2rem;
  }
}
.pay-img {
  width: 100%;
  margin-top: 0.66rem;
  font-size: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .img-box {
    width: 0.88rem;
    /* height: 1.06rem; */
    img {
      width: 100%;
      /* height: 100%; */
    }
  }
  span {
    margin: 0.4rem 0;
    font-weight: bold;
    color: #4087f0;
  }
}

.title {
  color: #a7a7a7;
  font-size: 16px;
  padding: 20px;
}
.school_box {
  width: 335px;
  border-radius: 10px;
  background: #fff;
  margin: 0 auto;
  align-items: center;
  box-shadow: 0 1px 9px #b1c6ff52;
}
.sex_box {
  display: flex;
  align-items: center;
  margin: 0 0.28rem;
  border-bottom: 0.3px solid #eeee;
  padding: 0.28rem 0;
}
.student {
  font-weight: bold;
  color: #161616;
  font-size: 18px;
}
.txt {
  text-align: center;
  color: #8c8c8c;
  font-size: 18px;
}
.sex_box div {
  margin: 0 0.2rem;
}
/* .outer-box {
  width: 100%;
  height: 100%;
  .banner-box {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .info-box {
    padding: 0.2rem 0.2rem;
    background: #fff;
    padding: 0.2rem 0.2rem;
    height: 1rem;
    border-radius: 0.1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.6rem;
    box-sizing: border-box;
  }
  .sub-box {
    padding: 0.2rem 0.2rem;
  }
} */
</style>
