<template>
  <div class="main-box" v-cloak>
    <div class="img-box">
      <img src="../../assets/img/huamei.jpg" alt="" />
    </div>
    <div class="content-box">
      <div class="review-box" v-if="orderData.code == 0">
        <div>{{ orderData.data.text }}</div>
        <div class="money">￥{{ orderData.data.total_amount }}元</div>
      </div>
      <div class="review-box" v-else>
        <div>{{ orderData.msg }}</div>
      </div>
      <div class="sub-box" v-show="!(orderData.data.status == 4)">
        <van-button type="info" block @click="toPayPage">立即支付</van-button>
      </div>
      <div class="remark-box" v-show="orderData.data.status == 4">
        您已成功缴费，请勿重复提交，如有疑问请联系负责老师华美招办咨询热线：<a
          href="tel:19903111963"
          >19903111963</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getOrderInfo } from "@/api/topay.js";
import { getCityInfo } from "@/api/report.js";
import { Toast } from "vant";
export default {
  name: "PayIndex",
  data: () => {
    return {
      name: "",
      pay_type: "",
      orderData: {},
    };
  },
  mounted() {
    this.getCityInfo();
    // this.getOrderInfo();
    /* this.getbrowertype().then(() => {
      this.getOrderInfo();
    }); */
  },
  methods: {
    /* 判断是微信还是支付宝 */
    getbrowertype() {
      return new Promise((resolve) => {
        var ua = navigator.userAgent.toLowerCase();
        console.log("微信浏览器还是支付宝", ua);
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          this.pay_type = "wxpay";
          resolve();
        } else if (ua.match(/Alipay/i) == "alipay") {
          this.pay_type = "alipay";
          resolve();
        } else {
          Toast("请使用微信或支付宝支付");
        }
      });
    },
    /* 请求支付信息跳转 */
    toPayPage() {
      Toast.loading({
        message: "数据加载中...",
        forbidClick: true,
      });
      let info = { pay_type: "wxpay" };
      let data = { request: JSON.stringify(info) };
      getOrderInfo(data).then((res) => {
        console.log("订单数据", res);
        Toast.clear();
        if (res.code == 0) {
          // this.orderData = res;
          location.href = res.data.openlink;
        } else {
          // this.orderData = res;
          Toast(res.msg);
        }
      });
    },
    /* 请求学生信息 */
    getCityInfo() {
      Toast.loading({
        message: "数据加载中...",
        forbidClick: true,
      });
      let info = {
        is_region: 0,
      };
      let data = {
        request: JSON.stringify(info),
      };
      getCityInfo(data).then((res) => {
        Toast.clear();
        console.log("获取学生信息", res);
        if (res.code == 0) {
          this.orderData = res;
        } else {
          this.orderData = res;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.main-box {
  background: #f5f5f5;
  height: 100vh;
}
.img-box {
  width: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
.content-box {
  width: 100%;
  padding: 0 0.4rem;
  box-sizing: border-box;
  .review-box {
    margin: 1rem 0;
    padding: 1rem 0;
    width: 100%;
    height: 1.5rem;
    background: #fff;
    border-radius: 0.2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 0.5rem;
    box-sizing: border-box;
  }
  .sub-box {
    width: 100%;
    .money {
      width: 100%;
      height: 1rem;
      padding: 0.2rem;
      margin: 0.5rem 0;
      box-sizing: border-box;
      background: #dededd;
      border-radius: 0.2rem;
      font-size: 0.5rem;
      display: flex;
      align-items: flex-end;
    }
  }
}
.remark-box {
  color: red;
  margin-top: 1rem;
  line-height: 0.5rem;
}
</style>
