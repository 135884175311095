
<template>
  <div class="page">
    <img src="/image/start.png" alt="" />
  </div>
</template>

<script>
import { get_config_info, get_login } from "@/api/code.js";
import { Toast, Dialog } from "vant";

export default {
  data() {
    return {
      checked: [],
      REDIRECT_URI: encodeURIComponent("http://h5.pay.fangaoyun.com/h5code"),
      SCOPE: "snsapi_userinfo",
      hash: "",
      appid: "",
      service_provider: "",
      plat_form: "",
      cateList: [],
      img_url: "",
      config_code_list: "",
      username: "",
      length_list: 0,
      money_list: 0,
      formList: {},
    };
  },
  mounted() {
    this.getUrlParams();
  },
  methods: {
    // 获取url参数并保存到本地存储中
    getUrlParams() {
      var url_string = window.location.href;
      var url = new URL(url_string);
      console.log(url, "url=======");
      const hash_list = url.searchParams.get("hash")
        ? url.searchParams.get("hash")
        : "";
      const auth_code = url.searchParams.get("code")
        ? url.searchParams.get("code")
        : "";

      localStorage.setItem("auth_code", auth_code);
      if (hash_list) {
        this.hash = hash_list;
        localStorage.setItem("hash", hash_list);
        this.getConfigInfo();
      } else {
        this.getLogin();
      }
    },
    //获取配置信息
    getConfigInfo() {
      const data = {
        request: JSON.stringify({
          hash: this.hash,
        }),
      };
      get_config_info(data).then((res) => {
        if (res.code != 0) {
          return Toast.fail(res.msg);
        }
        if (res.data.cate_list) {
          res.data.cate_list.forEach((val) => {
            val.amount = val.amount == "0.00" ? "" : val.amount;
            val.checked = false;
            val.disabled = false;
            if (val.other_setting == 1) {
              val.checked = true;
              val.disabled = true;
              this.length_list += 1;
              this.money_list += Number(val.amount);
            }
          });
        }
        this.cateList = res.data.cate_list;
        this.config_code_list = res.data.codeInfo;
        this.service_provider = res.data.service_config.id;
        localStorage.setItem("plat_form", res.data.codeInfo.pay_status);
        localStorage.setItem("service_provider", res.data.service_config.id);
        this.appid = res.data.service_config.appid;
        this.plat_form = res.data.codeInfo.pay_status; //1:微信 2：支付宝 0：均可
        this.img_url = res.data.codeInfo.header_url;
        this.formList = res.data.form
          ? JSON.parse(res.data.form.template_json).FcDesignerRule
          : "";
        console.log(this.formList, "formList====");
        if (res.data.codeInfo.state != 1) {
          return Dialog.alert({
            title: "当前活动已结束，请留意后续活动通知！",
            showConfirmButton: false,
            showCancelButton: false,
          });
        }
        this.getbrowertype(res.data.codeInfo.pay_status);
      });
    },

    //判断打开环境
    getbrowertype(val) {
      var ua = navigator.userAgent.toLowerCase();
      this.show = false;
      if (val == 0) {
        if (
          ua.match(/MicroMessenger/i) != "micromessenger" ||
          ua.match(/Alipay/i) != "alipay"
        ) {
          Dialog.alert({
            title: "请使用微信或支付宝打开",
            showConfirmButton: false,
            showCancelButton: false,
          });
        }
      } else if (val == 1) {
        if (ua.match(/MicroMessenger/i) != "micromessenger") {
          Dialog.alert({
            title: "请使用微信打开",
            showConfirmButton: false,
            showCancelButton: false,
          });
        }
      } else if (val == 2) {
        if (ua.match(/Alipay/i) != "alipay") {
          Dialog.alert({
            title: "请使用支付宝打开",
            showConfirmButton: false,
            showCancelButton: false,
          });
        }
      }
      if (localStorage.getItem("auth_code") == "") {
        this.getCodeList();
      }
    },
    //获取code
    getCodeList() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        const authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${this.REDIRECT_URI}&response_type=code&scope=${this.SCOPE}&state=${this.hash}#wechat_redirect`;
        window.location.href = authUrl;
      }
    },
    //登录
    getLogin() {
      const data = {
        request: JSON.stringify({
          service_provider: localStorage.getItem("service_provider"),
          code: localStorage.getItem("auth_code"),
          plat_form: 1,
        }),
      };
      get_login(data).then((res) => {
        if (res.code != 0) {
          return Toast.fail(res.msg);
        }
        localStorage.setItem("token", res.data.token);
        this.$router.replace("/formcode");
      });
    },
  },
};
</script>

<style scoped>
.page {
  margin: 0;
  padding: 0;
}

.img {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>