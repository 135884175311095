<template>
  <div class="content-box">
    <div class="banner-box" v-if="projectInfo.h5_banner">
      <img :src="projectInfo.h5_banner" />
    </div>
    <div class="text">
      <div class="img-box">
        <img src="../../../assets/img/sub.png" />
      </div>
      <div class="sub_text">
        <div>信息已提交</div>
        <div class="text">{{headerInfo.complate_tip}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projectInfo: "",
      headerInfo: "",
    };
  },
  mounted() {
    let projectInfo = localStorage.getItem("projectInfo");
    let headerInfo = localStorage.getItem("headerInfo");
    this.projectInfo = JSON.parse(projectInfo);
    this.headerInfo = JSON.parse(headerInfo);
  },
};
</script>

<style lang="less" scoped>
.content-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .banner-box {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .text {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    font-size: 0.36rem;
    color: #333;
  }
}
.sub_text {
  text-align: center;
  .text{
    margin-top: 10px;
  }
}
</style>
