<template>
  <div class="content-box">
    <div class="banner-box" v-if="projectInfo.h5_banner">
      <img :src="projectInfo.h5_banner" />
    </div>
    <div class="login-box">
      <van-cell-group>
        <van-field
          v-model="userId"
          label="用户名"
          placeholder="请输入手机号|身份证号|编号"
        />
        <van-field
          v-model="passwd"
          type="password"
          label="密码"
          placeholder="请输入密码"
        />
      </van-cell-group>
      <div class="imgcode-box">
        <div class="code-box">
          <div class="filed-box">
            <van-field
              v-model="imgcode"
              label="验证码"
              placeholder="请输入验证码"
            />
          </div>
          <div class="img-box" @click="getCodeImg">
            <img :src="src" />
          </div>
        </div>
        <div class="span-box">
          <span @click="toRegist('forget')">忘记密码</span>
          <span>看不清，点击图片刷新</span>
        </div>
      </div>
      <div class="btn-box">
        <div class="btn-btn">
          <van-button type="primary" block @click="toRegist('regist')">
            注册
          </van-button>
        </div>
        <div class="btn-btn" @click="toLogin">
          <van-button type="info" block>登录</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { toLogin } from "@/api/standard/registLogin";
import { getOpenId } from "@/api/standard/common.js";
import configopenid from "@/utils/wxconfig.js";
export default {
  name: "OnlyLoginIndex",
  data() {
    return {
      userId: "",
      passwd: "",
      code: "",
      projectInfo: "",
      src: "",
      imgId: "",
      payment_type_ext: "",
      paydata: "",
      imgcode: "",
    };
  },
  mounted() {
    if (this.payment_type_ext == "alipay") {
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src =
        "https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js";
      document.body.appendChild(s);
    }

    this.paydata = JSON.parse(localStorage.getItem("paydata")) || "";
    let projectInfo = localStorage.getItem("projectInfo");
    this.projectInfo = JSON.parse(projectInfo);
    let payment_type_ext = localStorage.getItem("payment_type_ext");
    this.payment_type_ext = payment_type_ext;
    this.getCodeImg();
    //微信环境请求openid
    if (payment_type_ext == "wxpay" && !this.paydata) {
      this.getUrlParams().then(() => {
        this.getOpenId();
      });
    }

    if (this.payment_type_ext == "alipay") {
      this.getUrlParams().then((res) => {
        console.log(res);
        this.getOpenId();
      });
    }
  },
  methods: {
    /* 获取页面数据 */
    getUrlParams() {
      return new Promise((resolve) => {
        let url_string = window.location.href; //window.location.href
        let url = new URL(url_string);
        if (this.payment_type_ext == "wxpay") {
          let code = url.searchParams.get("code");
          this.code = code;
        }
        if (this.payment_type_ext == "alipay") {
          let code = url.searchParams.get("auth_code");
          this.code = code;
        }

        resolve();
      });
    },
    /* code获取openid */
    getOpenId() {
      if (configopenid.isline) {
        let info = {
          open_type: this.payment_type_ext,
          code: this.code,
        };
        let data = {
          request: JSON.stringify(info),
        };
        getOpenId(data).then((res) => {
          console.log("微信信息xxx", res);
          if (res.code == 0) {
            this.paydata = res.data;
            localStorage.setItem("paydata", JSON.stringify(res.data));
          }
        });
      } else {
        this.paydata = configopenid.paydata;
        localStorage.setItem("paydata", JSON.stringify(this.paydata));
      }
    },
    /* 去注册 */
    toRegist(flag) {
      console.log("注册||充值", flag);
      if (
        this.projectInfo &&
        this.projectInfo.register_form_id &&
        flag == "regist"
      ) {
        this.$router.push({
          path: "/customRegist",
        });
      } else {
        this.$router.push({
          path: "/regist",
          query: {
            flag: flag,
          },
        });
      }
    },
    /* 获取图形码 */
    getCodeImg() {
      console.log("获取图形码");
      let tmp = Math.floor(Math.random() * (100000 - 0)) + 0;
      let dateTmp = new Date().getTime();
      this.imgId = `${this.projectInfo.project_id}${tmp}${dateTmp}`;
      let dommin = configopenid.isline
        ? configopenid.onlineimgurl
        : configopenid.devimgUrl;
      this.src = `${dommin}/h5.index.project.sendVerifyImage?id=${this.projectInfo.project_id}${tmp}${dateTmp}`;
    },
    /* 登录接口 */
    toLogin() {
      console.log("登录", this.passwd);
      let info = {
        mobile: this.userId,
        project_id: this.projectInfo.project_id,
        passwd: this.passwd,
        image_id: this.imgId,
        image_code: this.imgcode,
      };
      let data = {
        request: JSON.stringify(info),
      };
      toLogin(data).then((res) => {
        console.log("登录成功", res);
        if (res.code == 0) {
          localStorage.setItem("headerInfo", JSON.stringify(res.data));
          let data = res.data;
          let url = "/adit";
          if (data.status < 2) {
            url = "/onlyLoginForm";
          } else if (
            data.status == 2 ||
            data.status == 6 ||
            data.status == 10
          ) {
            url = "/adit";
          } else if (data.status == 3 || data.status == 5) {
            url = "/standardTopay";
          } else if (data.status == 4) {
            url = "/readyPay";
          } else if (data.status == 7) {
            url = "/submitSuccess";
          }
          this.$router.push(url);
        } else {
          Toast(res.msg);
          this.getCodeImg();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .banner-box {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .login-box {
    width: 100%;
    padding: 0.2rem 0.2rem;
    box-sizing: border-box;
    .imgcode-box {
      display: flex;
      flex-direction: column;
      .code-box {
        width: 100%;
        display: flex;
      }
      .img-box {
        flex: 1;
        height: 1rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .filed-box {
        width: 2rem;
        flex: 1 1 2rem;
        height: 1rem;
        van-field {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .span-box {
    color: #0073ff;
    font-size: 12px;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .btn-box {
    margin-top: 0.2rem;
    display: flex;
    .btn-btn {
      flex: 1;
      padding: 0 20px;
      box-sizing: border-box;
    }
    .van-button {
      width: 100%;
    }
  }
}
</style>
