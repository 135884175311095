<template>
  <div class="content-box">
    <div class="banner-box" v-if="projectInfo.h5_banner">
      <img :src="projectInfo.h5_banner" />
    </div>
    <div class="login-box">
      <van-cell-group>
        <van-field v-model="mobile" label="用户名" placeholder="请输入手机号" />
        <van-field
          v-model="passwd"
          type="password"
          label="密码"
          placeholder="请输入密码"
        />
        <van-field
          v-model="passwd2"
          type="password"
          label="重复密码"
          placeholder="请输入重复密码"
        />
      </van-cell-group>
      <div class="imgcode-box">
        <div class="filed-box">
          <van-field
            v-model="image_code"
            label="验证码"
            placeholder="请输入验证码"
          />
        </div>
        <div class="img-box" @click="getCodeImg">
          <img :src="src" />
        </div>
      </div>
      <div class="span-box">
        <span>看不清，点击图片刷新</span>
      </div>
      <van-cell-group v-if="projectInfo.verify_sms_code==1">
        <van-field
          v-model="verify_code"
          maxlength="6"
          center
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              @click="sendCode"
              v-if="isSend"
            >
              发送验证码
            </van-button>
            <div class="timer" v-else>{{ timer }}s</div>
          </template>
        </van-field>
      </van-cell-group>
      <div class="btn-box">
        <van-button
          type="info"
          block
          :disabled="btnDisabled"
          :loading="btnLoading"
          loading-text="请稍等..."
          @click="toRegist"
        >
          {{ btn_text }}
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { toRegist, sendCode, toReset } from '@/api/standard/registLogin.js'
import configopenid from '@/utils/wxconfig.js'
export default {
  name: 'OnlyLoginIndex',
  data() {
    return {
      mobile: '',
      passwd: '',
      passwd2: '',
      verify_code: '',
      image_code: '',
      projectInfo: '',
      src: '',
      image_id: '',
      isSend: true,
      timer: 60,
      btn_text: '注册',
      btnLoading: false,
      btnDisabled: false,
    }
  },
  mounted() {
    let flag = this.$route.query.flag
    flag === 'regist' ? (this.btn_text = '注册') : (this.btn_text = '重置密码')
    let projectInfo = localStorage.getItem('projectInfo')
    this.projectInfo = JSON.parse(projectInfo)
    this.getCodeImg()
  },
  methods: {
    /* 获取图形码 */
    getCodeImg() {
      console.log('获取图形码')
      let tmp = Math.floor(Math.random() * (100000 - 0)) + 0
      let dateTmp = new Date().getTime()
      this.image_id = `${this.projectInfo.project_id}${tmp}${dateTmp}`
      let dommin = configopenid.isline
        ? configopenid.onlineimgurl
        : configopenid.devimgUrl
      this.src = `${dommin}/h5.index.project.sendVerifyImage?id=${this.projectInfo.project_id}${tmp}${dateTmp}`
    },
    /* 倒计时 */
    countDown() {
      this.timer = 60
      let timer = setInterval(() => {
        this.timer--
        if (this.timer <= 0) {
          clearInterval(timer)
          this.isSend = true
        }
      }, 1000)
    },
    /* 发送验证码 */
    sendCode() {
      console.log('发送验证码')
      let flag = this.$route.query.flag
      if (this.mobile == '') {
        Toast('请输入手机号')
        return false
      }
      if (this.image_code == '') {
        Toast('请输入图形验证码')
        return false
      }
      let info = {
        mobile: this.mobile,
        image_code: this.image_code,
        image_id: this.image_id,
        project_id: this.projectInfo.project_id,
        action: flag,
      }
      let data = {
        request: JSON.stringify(info),
      }
      sendCode(data).then((res) => {
        console.log('验证码', res)
        if (res.code == 0) {
          this.isSend = false
          this.countDown()
        } else {
          Toast(res.msg)
          this.getCodeImg()
        }
      })
    },
    /* 注册||重置 */
    toRegist() {
      this.btnLoading = true
      this.btnDisabled = true
      console.log('注册', this.$route.query)
      let flag = this.$route.query.flag
      let info = {
        project_id: this.projectInfo.project_id,
        mobile: this.mobile,
        verify_code: this.verify_code,
        passwd: this.passwd,
        passwd2: this.passwd2,
        action: flag,
        image_id: this.image_id,
        image_code: this.image_code,
      }
      let data = {
        request: JSON.stringify(info),
      }
      if (flag == 'regist') {
        toRegist(data)
          .then((res) => {
            console.log('注册成功', res)
            this.btnLoading = false
            this.btnDisabled = false
            if (res.code == 0) {
              localStorage.setItem('headerInfo', JSON.stringify(res.data))
              let url = '/onlyLoginForm'
              this.$router.push(url)
            } else {
              Toast(res.msg)
              this.getCodeImg()
            }
          })
          .catch((err) => {
            console.log('注册失败', err)
            this.btnLoading = false
            this.btnDisabled = false
          })
      } else {
        toReset(data)
          .then((res) => {
            console.log('重置成功', res)
            this.btnLoading = false
            this.btnDisabled = false
            if (res.code == 0) {
              this.$router.go(-1)
            } else {
              Toast(res.msg)
              this.getCodeImg()
            }
          })
          .catch((err) => {
            console.log('重置失败', err)
            this.btnLoading = false
            this.btnDisabled = false
          })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.content-box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .banner-box {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .login-box {
    width: 100%;
    padding: 0.2rem 0.2rem;
    box-sizing: border-box;
    .imgcode-box {
      display: flex;
      .img-box {
        flex: 1;
        height: 1rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .filed-box {
        width: 2rem;
        flex: 1 1 2rem;
        height: 1rem;
        van-field {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .span-box {
    text-align: right;
    color: #0073ff;
    font-size: 12px;
    padding-top: 10px;
  }
  .btn-box {
    margin-top: 0.2rem;
    .van-button {
      width: 100%;
    }
  }
  .timer {
    width: 2rem;
    text-align: center;
  }
}
</style>
