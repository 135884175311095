var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-box"},[(
      (_vm.projectInfo.open_type == '' ||
        _vm.payment_type_ext == _vm.projectInfo.open_type) &&
      _vm.payment_type_ext != ''
    )?_c('img',{attrs:{"src":_vm.projectInfo.h5_ad}}):_vm._e(),(
      (_vm.projectInfo.open_type == '' && _vm.payment_type_ext == '') ||
      (_vm.projectInfo.open_type != '' &&
        _vm.projectInfo.open_type != _vm.payment_type_ext)
    )?_c('div',{staticClass:"show-qrcode"},[(_vm.show_tip)?_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.projectInfo.school_name))]):_vm._e(),(_vm.show_tip)?_c('div',{staticClass:"qrcode"},[_c('div',{staticClass:"qrcode-bg"},[_c('img',{attrs:{"src":_vm.projectInfo.project_qrcode,"alt":""}})]),_c('div',{staticClass:"tip"},[_c('div',{staticClass:"pay-tip"},[_c('span',[_vm._v("操作步骤：")]),_vm._m(0),_c('div',{staticClass:"tip-1"},[_vm._v(" 2.打开 "),_c('span',{staticClass:"red"},[_vm._v(_vm._s(_vm.open_type_text)+"，点击”扫一扫“ ")]),_vm._v(",选择相册，选中保存的二维码，进入项目 ")])])])]):_vm._e()]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._v("1."),_c('span',{staticClass:"red"},[_vm._v("长按图片")]),_vm._v("，保存到相册")])}]

export { render, staticRenderFns }