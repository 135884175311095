import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/login";
import Register from "@/views/register";
import Report from "@/views/report";
import Review from "@/views/review";
import Pay from "@/views/topay";
import TeacherLogin from "@/views/login/teacherLogin.vue";
import ChooseTap from "@/views/chooseTap";
//标准版
import SuccessPage from "@/views/standard/noRegistForm/successPage";
import Standard from "@/views/standard";
import noRegistForm from "@/views/standard/noRegistForm";
import OnlyLogin from "@/views/standard/onlyLogin";
import onlyLoginForm from "@/views/standard/onlyLogin/onlyLoginForm";
import Adit from "@/views/standard/onlyLogin/audit";
import StandardTopay from "@/views/standard/onlyLogin/standardTopay";
import ReadyPay from "@/views/standard/onlyLogin/readyPay";
import Notification from "@/views/standard/onlyLogin/notification";
import SubmitSuccess from "@/views/standard/onlyLogin/submitSuccess";
import RegistLogin from "@/views/standard/registLogin";
import Regist from "@/views/standard/registLogin/regist";
import customRegist from "@/views/standard/registLogin/customRegist";
import notificationImage from "@/views/standard/onlyLogin/notificationImage";
import h5login from "@/views/login/h5login";
import h5logincopy from "@/views/login/h5logincopy";
import h5code from "@/views/login/h5code";
import formcode from "@/views/formcode/index";
import payList from "@/views/topay/payList";
import payListCopy from "@/views/topay/payListCopy";
import payInfo from "@/views/topay/payInfo";
import payDetails from "@/views/topay/payDetails";
import link from "@/views/link/index.vue";
//新华
import projectLogin from "@/views/projectList/login";
import projectList from "@/views/projectList/index";
import studentInfo from "@/views/projectList/studentInfo";
import addStudent from "@/views/projectList/addStudent";
import addRemark from "@/views/projectList/addRemark";
import projectCode from "@/views/projectList/projectCode";
import schoolSelect from "@/views/projectList/schoolSelect";
import DingLogin from "@/views/dingding/login";
import BillList from "@/views/dingding/list";
import BillInfo from "@/views/dingding/info";
import userList from "@/views/dingding/user";

import zhongzhuan from "@/views/zhongzhuan";
Vue.use(VueRouter);

const routes = [
  {
    path: "/zhongzhuan",
    name: "zhongzhuan",
    component: zhongzhuan,
  },
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/report",
    name: "Report",
    component: Report,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/review",
    name: "Review",
    component: Review,
  },
  {
    path: "/toPay",
    name: "Pay",
    component: Pay,
  },
  {
    path: "/payList",
    name: "payList",
    component: payList,
  },
  {
    path: "/teacherLogin",
    name: "TeacherLogin",
    component: TeacherLogin,
  },
  {
    path: "/chooseTap",
    name: "ChooseTap",
    component: ChooseTap,
  },
  {
    path: "/link/:hash",
    name: "Link",
    component: link,
  },
  //标准版
  {
    path: "/standard",
    name: "Standard",
    component: Standard,
  },
  {
    path: "/noRegistForm",
    name: "noRegistForm",
    component: noRegistForm,
  },
  {
    path: "/onlyLogin",
    name: "OnlyLogin",
    component: OnlyLogin,
  },
  {
    path: "/onlyLoginForm",
    name: "onlyLoginForm",
    component: onlyLoginForm,
  },
  {
    path: "/adit",
    name: "Adit",
    component: Adit,
  },
  {
    path: "/standardTopay",
    name: "StandardTopay",
    component: StandardTopay,
  },
  {
    path: "/readyPay",
    name: "ReadyPay",
    component: ReadyPay,
  },
  {
    path: "/notification",
    name: "Notification",
    component: Notification,
  },
  {
    path: "/registLogin",
    name: "RegistLogin",
    component: RegistLogin,
  },
  {
    path: "/regist",
    name: "Regist",
    component: Regist,
  },
  {
    path: "/customRegist",
    name: "customRegist",
    component: customRegist, 
  },
  {
    path: "/submitSuccess",
    name: "SubmitSuccess",
    component: SubmitSuccess,
  },
  {
    path: "/successPage",
    name: "SuccessPage",
    component: SuccessPage,
  },
  {
    path: "/notificationImage",
    name: "notificationImage",
    component: notificationImage,
  },
  {
    path: "/h5login",
    name: "h5login",
    component: h5login,
  },
  {
    path: "/h5logincopy",
    name: "h5logincopy",
    component: h5logincopy,
  },
  {
    path: "/h5code",
    name: "h5code",
    component: h5code,
  },
  {
    path: "/formcode",
    name: "formcode",
    component: formcode,
  },
  {
    path: "/payDetails",
    name: "payDetails",
    component: payDetails,
  },
  {
    path: "/payListCopy",
    name: "payListCopy",
    component: payListCopy,
  },
  {
    path: "/payInfo",
    name: "payInfo",
    component: payInfo,
  },
  //新华
  {
    path: "/projectLogin",
    name: "projectLogin",
    component: projectLogin,
  },
  {
    path: "/projectList",
    name: "projectList",
    component: projectList,
  },
  {
    path: "/studentInfo",
    name: "studentInfo",
    component: studentInfo,
  },
  {
    path: "/addStudent",
    name: "addStudent",
    component: addStudent,
  },
  {
    path: "/addRemark",
    name: "addRemark",
    component: addRemark,
  },
  {
    path: "/projectCode",
    name: "projectCode",
    component: projectCode,
  },
  {
    path: "/schoolSelect",
    name: "schoolSelect",
    component: schoolSelect,
  },
  //钉钉
  {
    path: "/Ding/DingLogin",
    name: "DingLogin",
    component: DingLogin,
  },
  {
    path: "/Ding/BillList",
    name: "BillList",
    component: BillList,
  },
  {
    path: "/Ding/BillInfo",
    name: "BillInfo",
    component: BillInfo,
  },
  {
    path: "/Ding/userList",
    name: "userList",
    component: userList,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
//路由导航首位，所有页面的导航都会经过这里
router.beforeEach((to, from, next) => {
  if (
    localStorage.getItem("projectInfo") &&
    JSON.parse(localStorage.getItem("projectInfo")).school_name
  ) {
    let school_name = JSON.parse(
      localStorage.getItem("projectInfo")
    ).school_name;
    document.title = school_name;
  } else {
    document.title = "";
  }
  /* if (to.meta.title) { //判断是否有标题
    console.log('title', to.meta.title)
    document.title = '华美学校招生系统'
  } else {
    console.log('title', to.meta.title)
    document.title = '华美学校招生系统'
  } */
  next(); //执行进入路由，如果不写就不会进入目标页
  // const header = JSON.parse(window.localStorage.getItem('header'))
  /* if (to.path !== '/login') { //校验非登录页面的登录状态
    if (header) { //已登录正常通过
      next()
    } else { //没有登录跳转登录页面
      next('/login')
    }
  } else { //登录页面正常允许通过,
    next()
  } */
});
export default router;
