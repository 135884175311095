import request from '@/utils/request.js'
/* 获取项目信息接口 */
const getData = (data) => {
	return request({
		url: '/h5.index.teacher.student.list',
		method: 'post',
		data: data
	})
}
/* 审核通过 */
const handleStuInfoPrice = (data) => {
	return request({
		url: '/h5.index.teacher.student.checkstatus',
		method: 'post',
		data: data
	})
}
export {
	getData,
	handleStuInfoPrice
}