import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/index.css'
import '@/assets/css/reset.css'
import '@/assets/js/rem.js'
import Vant from 'vant'
import 'vant/lib/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import formCreate from '@form-create/element-ui'
import FcDesigner from '@form-create/designer'
Vue.use(Vant)
Vue.use(FcDesigner)
Vue.use(formCreate)
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.config.ignoredElements = ['wx-open-launch-weapp'];
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')